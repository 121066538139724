import React from "react";
import { Link } from "react-router-dom";
import { src } from "./ultis/src";
import { Axios } from "./ultis/axios";

export default class HomeStudent extends React.Component {
  state = { now: 1, end: false };

  HSIL = React.createRef();

  nextItem = async () => {
    let x = this.state.now + 1;
    if (x === 5) {
      return;
    }
    await this.setState({ now: x });
    if (this.state.now === 4) {
      this.setState({ end: true });
    } else {
      this.setState({ end: false });
    }
  };

  prevItem = async () => {
    let x = this.state.now - 1;
    if (x === 0) {
      return;
    }
    await this.setState({ now: x });
    if (this.state.now === 4) {
      this.setState({ end: true });
    } else {
      this.setState({ end: false });
    }
  };

  async componentDidMount() {
    let x = await Axios.get("/gedu/feel/hoc-vien-xuat-sac");
    if (x.data) {
      this.setState({ ...x.data });
      let y = x.data.list.length;
      this.HSIL.current.style.width = `${y * 300 - 100}px`;
    }
  }

  prev = React.createRef();
  next = React.createRef();

  render() {
    return (
      <div className="HomeCourse HomeStudent">
        <div className="HCT">HỌC VIÊN TIÊU BIỂU</div>
        <div className="HCL">
          <i
            className={
              this.state.now === 1
                ? "fas fa-chevron-left HCIprev c-gray"
                : "fas fa-chevron-left HCIprev"
            }
            onClick={this.prevItem}
            ref={this.prev}
          ></i>
          <i
            className={
              this.state.end === true
                ? "fas fa-chevron-right HCInext c-gray"
                : "fas fa-chevron-right HCInext"
            }
            onClick={this.nextItem}
            ref={this.next}
          ></i>
          <div className="HSILC">
            {this.state.list ? (
              <div
                className="HSIL"
                style={{
                  left:
                    this.state.now === 1
                      ? "0"
                      : this.state.now === 2
                      ? "-300px"
                      : this.state.now === 3
                      ? "-600px"
                      : "-900px",
                }}
                ref={this.HSIL}
              >
                {this.state.list.map((item) => (
                  <Link
                    to={`/feel/hoc-vien-xuat-sac/${item.id}`}
                    className="HSI"
                    key={item.id}
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                  >
                    <div className="HSIimageC">
                      <div
                        className="HSIimage"
                        style={{
                          backgroundImage: `url(${src}/${item.img})`,
                        }}
                      ></div>
                    </div>
                    <div className="HCItitle HSItitle">{item.title}</div>
                  </Link>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
