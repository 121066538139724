import React from "react";
import { Link, withRouter } from "react-router-dom";

class FormTitle extends React.Component {
  render() {
    return (
      <div className="FormTitle" onClick={this.props.offNBC}>
        <div className="FTC">
          <div className="FTT">
            <span className="FTTC">{this.props.category}</span>
          </div>
          {this.props.catelist.map((item) => (
            <Link
              to={`${item.url}`}
              key={item.url}
              className={
                this.props.location.pathname !== item.url
                  ? "FTCL"
                  : "FTCL fw-bd"
              }
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              {item.text}
            </Link>
          ))}
        </div>
      </div>
    );
  }
}

export default withRouter(FormTitle);
