import React from "react";
import { Axios } from "./ultis/axios";

export default class Contact extends React.Component {
  state = {
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 11,
    name: "",
    data: "",
    email: "",
    phone: "",
  };

  Name = React.createRef();
  Data = React.createRef();
  Email = React.createRef();
  Phone = React.createRef();
  NameErr = React.createRef();
  DataErr = React.createRef();
  EmailErr = React.createRef();
  PhoneErr = React.createRef();

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.Name.current.style.border = "2px solid #0ab1e7";
    this.NameErr.current.innerText = "";
    this.Data.current.style.border = "2px solid #0ab1e7";
    this.DataErr.current.innerText = "";
    this.Email.current.style.border = "2px solid #0ab1e7";
    this.EmailErr.current.innerText = "";
    this.Phone.current.style.border = "2px solid #0ab1e7";
    this.PhoneErr.current.innerText = "";
  };

  validateEmail = (email) => {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  onSubmit = async () => {
    if (this.state.name === "") {
      this.Name.current.style.border = "2px solid red";
      this.NameErr.current.innerText = "Please enter name";
    }
    if (this.state.data === "") {
      this.Data.current.style.border = "2px solid red";
      this.DataErr.current.innerText = "Please enter somethings";
    }
    if (this.state.email === "") {
      this.Email.current.style.border = "2px solid red";
      this.EmailErr.current.innerText = "Please enter email";
    } else if (!this.validateEmail(this.state.email)) {
      console.log(this.validateEmail(this.state.email));
      this.Email.current.style.border = "2px solid red";
      this.EmailErr.current.innerText = "Email false";
    }
    if (this.state.phone === "") {
      this.Phone.current.style.border = "2px solid red";
      this.PhoneErr.current.innerText = "Please enter phone number";
    } else if (this.state.phone.length < 10 || this.state.phone.length > 15) {
      this.Phone.current.style.border = "2px solid red";
      this.PhoneErr.current.innerText = "Phone false";
    }
    if (
      this.NameErr.current.innerText === "" &&
      this.DataErr.current.innerText === "" &&
      this.EmailErr.current.innerText === "" &&
      this.PhoneErr.current.innerText === ""
    ) {
      Axios.post("/gedu/sendcontact", {
        name: this.state.name,
        data: this.state.data,
        phone: this.state.phone,
        email: this.state.email,
      });
      alert(
        "Cảm ơn đã liên hệ, GEMS EDU sẽ liên hệ tư vấn trong thời gian sớm nhất"
      );
    }
  };
  render() {
    return (
      <div className="Contact bc-s">
        <div className="ContactForm d-flex fd-c ta-l">
          <div className="CTT">
            <b>HỆ THỐNG ANH NGỮ QUỐC TẾ GEMS EDU</b>
          </div>
          <div className="fs-20">
            <b>Gems Edu Hà Nội</b>
          </div>
          <div>
            <div className="RO">
              <i className="fas fa-building ROI"></i>
              <div>
                <b>Trụ sở chính: </b>Tầng 5, tòa nhà số 2, Vương Thừa Vũ, Khương
                Trung, Thanh Xuân, Hà Nội
              </div>
            </div>
            <div className="RO">
              <i className="fas fa-map-marker-alt ROI"></i>
              <div>
                <b>Cơ sở An Bình City: </b>Shophouse A6 - 05, KĐT An Bình City,
                232 Phạm Văn Đồng, Bắc Từ Liêm, Hà Nội
              </div>
            </div>
            <div className="RO">
              <i className="fas fa-map-marker-alt ROI"></i>
              <div>
                <b>Cơ sở Kim Văn Kim Lũ: </b>Shophouse 04, tòa C Vinaconex,
                Nguyễn Xiển, Hà Nội
              </div>
            </div>
          </div>
          <div className="FooterContact">
            <div className="RO">
              <i className="fas fa-phone-alt ROI"></i>
              <b>Hotline:&nbsp;</b>
              <a href="tel:0969206526">
                <span className="c-b">0969206526</span>
              </a>
            </div>
            <div className="RO">
              <i className="fas fa-envelope ROI"></i>
              <b>Email:&nbsp;</b>
              <a href="mailto:contact.gemsedu@gmail.com">
                <span className="c-b">contact.gemsedu@gmail.com</span>
              </a>
            </div>
          </div>
          <div className="mt-20 DivMap">
            <iframe
              title="map"
              className="MapGG"
              frameBorder="0"
              style={{ border: 0 }}
              src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJk4SBVPqsNTERN5BsMH6HIes&key=AIzaSyCVpuhfZ41cXreqjXupJyCdp1GEvhHXpVw"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <div className="FormRegister ContactForm CTF2">
          <div className="FRT CFT">
            <div className="FRT1">LIÊN HỆ</div>
            <div className="FRT2">
              Hãy nhập đầy đủ thông tin để chúng tôi phản hồi sớm nhất cho quý
              khách!
            </div>
          </div>
          <input
            ref={this.Name}
            name="name"
            onChange={this.onChange}
            className="FRI CFI"
            type="text"
            placeholder="Tên"
          ></input>
          <div ref={this.NameErr} className="c-r"></div>
          <input
            ref={this.Phone}
            name="phone"
            onChange={this.onChange}
            className="FRI CFI"
            type="number"
            placeholder="Số điện thoại"
          ></input>
          <div ref={this.PhoneErr} className="c-r"></div>
          <input
            ref={this.Email}
            name="email"
            onChange={this.onChange}
            className="FRI CFI"
            type="text"
            placeholder="Email"
          ></input>
          <div ref={this.EmailErr} className="c-r"></div>
          <textarea
            ref={this.Data}
            name="data"
            onChange={this.onChange}
            className="FRI CFTA"
            type="text"
            placeholder="Nội dung"
          ></textarea>
          <div ref={this.DataErr} className="c-r"></div>
          <div className="FRB" onClick={this.onSubmit}>
            <i className="fas fa-check"></i>&nbsp;&nbsp;Gửi thông tin
          </div>
        </div>
      </div>
    );
  }
}
