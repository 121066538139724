import React from "react";

export default class FormContent extends React.Component {
  render() {
    return (
      <div className="FormContent">
        <div className="FCT">
          <span className="FCTC">{this.props.content.title}</span>
        </div>
        {this.props.type === "content" ? (
          <div
            dangerouslySetInnerHTML={{ __html: this.props.content.content }}
            className="mt-35"
          ></div>
        ) : (
          "loading"
        )}
      </div>
    );
  }
}
