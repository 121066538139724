import React from "react";
import { Axios } from "../ultis/axios";
import { src } from "../ultis/src";

export default class IMG extends React.Component {
  state = {
    img: true,
    data: [],
  };

  getVideo = async () => {
    if (this.state.img === true) {
      let x = await Axios.get("/gedu/video");
      await this.setState({ img: false, data: [...x.data] });
      this.render();
    }
  };

  getImg = async () => {
    if (this.state.img === false) {
      let x = await Axios.get("/gedu/img");
      await this.setState({ img: true, data: [...x.data] });
      this.render();
    }
  };

  async componentDidMount() {
    if (this.state.img === true) {
      let x = await Axios.get("/gedu/img");
      this.setState({ data: [...x.data] });
    }
  }

  render() {
    return (
      <div className="FormContent">
        <div className="FCT">
          <span className="FCTC">Hình ảnh và Video</span>
        </div>
        <div className="ImgVideo">
          <div className="dbiv">
            <div
              className={
                this.state.img === true ? "bg-b c-w biv" : "bg-g c-bl biv"
              }
              onClick={this.getImg}
            >
              Hình ảnh
            </div>
            <div
              className={
                this.state.img === false ? "bg-b c-w biv" : "bg-g c-bl biv"
              }
              onClick={this.getVideo}
            >
              Video
            </div>
          </div>

          {this.state.img === true ? (
            <div className="ListImgVideo">
              {this.state.data.map((item) => (
                <div key={item.img}>
                  <img
                    className="IAVImg"
                    alt={item.img}
                    src={`${src}/${item.img}`}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="ListImgVideo">
              {this.state.data.map((item) => (
                <video
                  key={item.video}
                  controls
                  src={`${src}/${item.video}`}
                  type="video/mp4"
                  className="video"
                ></video>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}
