import React from "react";
import { Route, withRouter } from "react-router-dom";
import PostList from "./News/PostList";
import Post from "./News/Post";
import DTKD from "./News/DTKD";
import IMG from "./News/IMG";
import TD from "./News/TD";
import LHTN from "./News/LHTN";
class News extends React.Component {
  state = {
    category: "TIN TỨC SỰ KIỆN",
    catelist: [
      { url: "/news/tin-tuc-va-su-kien", text: "Tin tức và Sự kiện" },
      { url: "/news/doi-tac-kinh-doanh", text: "Đối tác kinh doanh" },
      { url: "/news/hinh-anh-va-video", text: "Hình ảnh và Video" },
      { url: "/news/lich-hoc-trai-nghiem", text: "Lịch học trải nghiệm" },
      { url: "/news/tuyen-dung", text: "Tuyển dụng" },
    ],
  };

  async componentDidMount() {
    this.props.setCF(this.state.category, this.state.catelist);
  }

  render() {
    return (
      <div className="BodySub bc-s News">
        <Route
          path="/news/tin-tuc-va-su-kien"
          exact
          render={() => <PostList />}
        ></Route>
        <Route
          path="/news/tin-tuc-va-su-kien/:id"
          render={() => <Post />}
        ></Route>
        <Route path="/news/doi-tac-kinh-doanh" render={() => <DTKD />}></Route>
        <Route path="/news/hinh-anh-va-video" render={() => <IMG />}></Route>
        <Route path="/news/lich-hoc-trai-nghiem" render={() => <LHTN />}></Route>
        <Route path="/news/tuyen-dung" render={() => <TD />}></Route>
      </div>
    );
  }
}

export default withRouter(News);
