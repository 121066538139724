import axios from "axios";

export const Axios = axios.create({
  baseURL: "http://14.225.5.47:9222",
});

// export const HeadCtx = (ctx) => {
//   return {
//     headers: {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       Authorization: `Bearer ${ctx}`,
//     },
//   };
// };
