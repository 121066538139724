import React from "react";
import F1 from "./img/Feel3.jpg";
import F2 from "./img/Feel2.jpg";
import F3 from "./img/Feel1.jpg";
import F4 from "./img/Feel4.jpg";
import F5 from "./img/Feel5.jpg";
import F6 from "./img/Feel6.jpg";
import { Link } from "react-router-dom";

export default class HomeFeel extends React.Component {
  state = { now: 1, end: false };

  nextItem = async () => {
    let x = this.state.now + 1;
    if (x === 4) {
      return;
    }
    await this.setState({ now: x });
    if (this.state.now === 3) {
      this.setState({ end: true });
    } else {
      this.setState({ end: false });
    }
    console.log(this.state.now, this.state.end);
  };

  prevItem = async () => {
    let x = this.state.now - 1;
    if (x === 0) {
      return;
    }
    await this.setState({ now: x });
    if (this.state.now === 3) {
      this.setState({ end: true });
    } else {
      this.setState({ end: false });
    }
    console.log(this.state.now, this.state.end);
  };

  prev = React.createRef();
  next = React.createRef();

  render() {
    return (
      <div className="HomeCourse HomeFeel">
        <div className="HCT HFT">CẢM NHẬN CỦA HỌC SINH VÀ PHỤ HUYNH</div>
        <div className="HCL HFL">
          <i
            className={
              this.state.now === 1
                ? "fas fa-chevron-left HCIprev HCIB c-gray HFprev"
                : "fas fa-chevron-left HCIprev HCIB HFprev"
            }
            onClick={this.prevItem}
            ref={this.prev}
          ></i>
          <i
            className={
              this.state.end === true
                ? "fas fa-chevron-right HCInext HCIB c-gray HFnext"
                : "fas fa-chevron-right HCInext HCIB HFnext"
            }
            onClick={this.nextItem}
            ref={this.next}
          ></i>
          <div className="HCILC HFILC">
            <div
              className={
                this.state.now === 1
                  ? "HCIL HFIL HCIL1"
                  : this.state.now === 2
                  ? "HCIL HFIL HCIL2"
                  : "HCIL HFIL HCIL3"
              }
            >
              <div className="HFI">
                <Link
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  to="/feel/cam-nhan-cua-hoc-vien"
                >
                  <div className="HF HF1">
                    <img src={F1} alt="x" className="HFImg" />
                    <div className="HFN">Bé Đặng Nhật Minh</div>
                  </div>
                </Link>

                <Link
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  to="/feel/cam-nhan-cua-phu-huynh"
                >
                  <div className="HF HF4">
                    <img src={F4} alt="x" className="HFImg" />
                    <div className="HFN">Trích lời mẹ bé Nhật Minh</div>
                  </div>
                </Link>
              </div>
              <div className="HFI">
                <Link
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  to="/feel/cam-nhan-cua-hoc-vien"
                >
                  <div className="HF HF2">
                    <img src={F2} alt="x" className="HFImg" />
                    <div className="HFN">Bé Lê Phương Nga</div>
                  </div>
                </Link>

                <Link
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  to="/feel/cam-nhan-cua-phu-huynh"
                >
                  <div className="HF HF5">
                    <img src={F5} alt="x" className="HFImg" />
                    <div className="HFN">Chia sẻ từ mẹ bé Diệp Khanh</div>
                  </div>
                </Link>
              </div>
              <div className="HFI">
                <Link
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  to="/feel/cam-nhan-cua-hoc-vien"
                >
                  <div className="HF HF3">
                    <img src={F3} alt="x" className="HFImg" />
                    <div className="HFN">Bé Nguyễn Tiến Dũng</div>
                  </div>
                </Link>
                <Link
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  to="/feel/cam-nhan-cua-phu-huynh"
                >
                  <div className="HF HF6">
                    <img src={F6} alt="x" className="HFImg" />
                    <div className="HFN">Chia sẻ từ 1 phụ huynh</div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
