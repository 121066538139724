import React from "react";
import { Axios } from "../ultis/axios";

export default class LHTN extends React.Component {
  state = {};
  async componentDidMount() {
    let x = await Axios.get("/gedu/news/lich-hoc-trai-nghiem");
    console.log(x.data);
    if (x.data) {
      this.setState({ ...x.data });
    }
  }

  render() {
    return (
      <div className="FormContent">
        <div className="FCT">
          {this.state ? <span className="FCTC">{this.state.title}</span> : null}
        </div>
        {this.state ? (
          <div
            dangerouslySetInnerHTML={{ __html: this.state.content }}
            className="mt-35"
          ></div>
        ) : (
          "loading"
        )}
      </div>
    );
  }
}
