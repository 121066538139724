import React from "react";
import { Link } from "react-router-dom";

export default class Footer extends React.Component {
  render() {
    return (
      <div className="Footer">
        <div className="F0 F1">
          <Link 
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
          to="/" className="Logo2"></Link>
          <div className="RO"></div>
          <div>
            <div className="RO">
              <i className="fas fa-building ROI"></i>
              <div>
                <b>Trụ sở chính: </b>Tầng 5, tòa nhà số 2, Vương Thừa Vũ, Khương
                Trung, Thanh Xuân, Hà Nội
              </div>
            </div>
            <div className="RO">
              <i className="fas fa-map-marker-alt ROI"></i>
              <div>
                <b>Cơ sở An Bình City: </b>Shophouse A6 - 05, KĐT An Bình City,
                232 Phạm Văn Đồng, Bắc Từ Liêm, Hà Nội
              </div>
            </div>
            <div className="RO">
              <i className="fas fa-map-marker-alt ROI"></i>
              <div>
                <b>Cơ sở Kim Văn Kim Lũ: </b>Shophouse 04, tòa C Vinaconex,
                Nguyễn Xiển, Hà Nội
              </div>
            </div>
          </div>
          <div className="FooterContact">
            <div className="RO">
              <i className="fas fa-phone-alt ROI"></i>
              <b>Hotline:&nbsp;</b>
              <a href="tel:0969206526">
                <span className="c-w">0969206526</span>
              </a>
            </div>
            <div className="RO">
              <i className="fas fa-envelope ROI"></i>
              <b>Email:&nbsp;</b>
              <a href="mailto:contact.gemsedu@gmail.com">
                <span className="c-w">contact.gemsedu@gmail.com</span>
              </a>
            </div>
          </div>
          <div className="Social">
            <a href="https://www.facebook.com/gemsedu.vn">
              <i className="fab fa-facebook c-w"></i>
            </a>
            <a href="https://www.youtube.com/channel/UC9yDz5eFDBB66VDnTYfcCYw">
              <i className="fab fa-youtube c-w"></i>
            </a>
          </div>
        </div>
        <div className="F0 F2">
          <div className="F21">
            <div className="F2.1">
              <div>
                <b>CHƯƠNG TRÌNH HỌC</b>
              </div>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/course/gems-stars"
              >
                <div className="c-w">Gems Star (3-5 tuổi)</div>
              </Link>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/course/gems-fighters"
              >
                <div className="c-w">Gems Fighters (6-11 tuổi)</div>
              </Link>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/course/gems-explorers"
              >
                <div className="c-w">Gems Explorers (12-15 tuổi)</div>
              </Link>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/course/luyen-thi-ielts"
              >
                <div className="c-w">Luyện thi Ielts</div>
              </Link>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/course/luyen-thi-cambridge"
              >
                <div className="c-w">Luyện thi Cambridge</div>
              </Link>
            </div>
            <div className="F2.2">
              <div>
                <b>GIỚI THIỆU</b>
              </div>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/introduce/ve-gems-edu"
              >
                <div className="c-w">Về Gems Edu</div>
              </Link>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/introduce/tam-nhin-va-su-menh"
              >
                <div className="c-w">Tầm nhìn và sứ mệnh</div>
              </Link>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/introduce/dao-tao-nhan-su"
              >
                <div className="c-w">Đào tạo nhân sự</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
