import React from "react";

export default class HomePromise extends React.Component {
  render() {
    return (
      <div className="HomePromise">
        <div className="HPIC"></div>
        <div className="HPT">
          <div className="WT">GEMS EDU cam kết</div>
          <div className="WL">
            <div className="WLB WL1"></div>
            <div className="WLI">
              <i className="far fa-check-circle c-o"></i>
              <span className="WLIT">Chất lượng dạy và học tiêu chuẩn</span>
            </div>
            <div className="WLB WL3"></div>
            <div className="WLI">
              <i className="far fa-check-circle c-o"></i>
              <span className="WLIT">Hỗ trợ ôn thi chứng chỉ Quốc tế</span>
            </div>
            <div className="WLB WL3"></div>
            <div className="WLI">
              <i className="far fa-check-circle c-o"></i>
              <span className="WLIT">Đảm bảo kiến thức sau từng buổi học</span>
            </div>
            <div className="WLB WL3"></div>
            <div className="WLI">
              <i className="far fa-check-circle c-o"></i>
              <span className="WLIT">Cam kết đầu ra sau thời gian ngắn</span>
            </div>
            <div className="WLB WL3"></div>
            <div className="WLI">
              <i className="far fa-check-circle c-o"></i>
              <div className="WLIT">
                Học viên tự tin giao tiếp, thuyết trình, phản biện, điểm thi cao
              </div>
            </div>
            <div className="WLB WL3"></div>
            <div className="WLI WL12">
              <i className="far fa-check-circle c-o"></i>
              <div className="WLIT">
                Cam kết đồng hành cùng học viên và phụ huynh
              </div>
            </div>
            <div className="WLB WL1"></div>
          </div>
        </div>
      </div>
    );
  }
}
