import React from "react";
import { Axios } from "./ultis/axios";
import FormContent from "./FormContent";
import { withRouter } from "react-router-dom";

class Course extends React.Component {
  state = {
    category: "KHÓA HỌC",
    catelist: [
      { url: "/course/gems-stars", text: "Gems Stars (3-5 tuổi)" },
      { url: "/course/gems-fighters", text: "Gems Fighters (6-11 tuổi)" },
      { url: "/course/gems-explorers", text: "Gems Explorers (12-15 tuổi)" },
      { url: "/course/luyen-thi-ielts", text: "Luyện thi IELTS" },
      { url: "/course/luyen-thi-cambridge", text: "Luyện thi Cambridge" },
      {
        url: "/course/cau-lac-bo-va-khoa-hoc-ngoai-khoa",
        text: "Câu lạc bộ và Khóa học ngoại khóa",
      },
      {
        url: "/course/lo-trinh-hoc-va-phuong-phap-dao-tao",
        text: "Lộ trình học tập và Phương pháp đào tạo",
      },
      { url: "/course/trai-he-supercamp", text: "Trại hè Supercamp" },
    ],
    content: "",
  };

  async componentDidUpdate() {
    if (this.props.location.pathname !== this.state.path) {
      this.setState({ path: this.props.location.pathname });
      this.setRender();
    }
  }

  setRender = async () => {
    this.setState({ path: this.props.location.pathname });
    let path = this.props.location.pathname.split("/");
    let x = await Axios.get(`/gedu/course/${path[2]}`);
    this.setState({ content: x.data });
    this.props.setCF(this.state.category, this.state.catelist);
  };

  async componentDidMount() {
    this.setRender();
  }
  render() {
    return (
      <div className="BodySub bc-s Course">
        <FormContent type="content" content={this.state.content} />
      </div>
    );
  }
}

export default withRouter(Course);
