import React from "react";
import FormContent from "./FormContent";
import { withRouter } from "react-router-dom";
import { Axios } from "./ultis/axios";

class Intro extends React.Component {
  state = {
    category: "GIỚI THIỆU",
    catelist: [
      { url: "/introduce/ve-gems-edu", text: "Về Gems Edu" },
      { url: "/introduce/tam-nhin-va-su-menh", text: "Tầm nhìn và sứ mệnh" },
      { url: "/introduce/dao-tao-nhan-su", text: "Đào tạo nhân sự" },
    ],
    content: "",
  };

  async componentDidUpdate() {
    if (this.props.location.pathname !== this.state.path) {
      this.setState({ path: this.props.location.pathname });
      this.setRender();
    }
  }

  setRender = async () => {
    this.props.setCF(this.state.category, this.state.catelist);
    this.setState({ path: this.props.location.pathname });
    let path = this.props.location.pathname.split("/");
    let x = await Axios.get(`/gedu/introduce/${path[2]}`);
    this.setState({ content: x.data });
  };

  async componentDidMount() {
    this.setRender();
  }

  render() {
    return (
      <div className="BodySub bc-s Intro">
        <FormContent type="content" content={this.state.content} />
      </div>
    );
  }
}

export default withRouter(Intro);
