import React from "react";
import { Link, withRouter } from "react-router-dom";

class NavBar extends React.Component {
  HPB = React.createRef();
  GT = React.createRef();
  GTB = React.createRef();
  KH = React.createRef();
  KHB = React.createRef();
  TT = React.createRef();
  TTB = React.createRef();
  CN = React.createRef();
  CNB = React.createRef();
  LHB = React.createRef();

  render() {
    return (
      <div
        className={
          this.props.location.pathname === "/" ? "NavBar" : "NavBar NavBar2"
        }
        onClick={this.props.offNBC}
      >
        <Link
          to="/"
          className={
            this.props.location.pathname === "/" ? "NBI Logo" : "NBI Logo Logo3"
          }
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        ></Link>
        <i className="fas fa-bars NavShort" onClick={this.props.onNBC}></i>
        <div className="Menu">
          <Link
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
            to="/"
            className={
              this.props.location.pathname === "/" ? "NBI p-r" : "NBI p-r c-w"
            }
            onMouseEnter={() => {
              this.HPB.current.style.transform = "scaleX(1)";
            }}
            onMouseLeave={() => {
              this.HPB.current.style.transform = "scaleX(0)";
            }}
          >
            <div
              className={
                this.props.location.pathname === "/" ? "NBB" : "NBB NBB2"
              }
              ref={this.HPB}
            ></div>
            TRANG CHỦ
          </Link>
          <div
            className="NBIOut"
            onMouseEnter={() => {
              this.GT.current.style.display = "flex";
              this.GTB.current.style.transform = "scaleX(1)";
            }}
            onMouseLeave={() => {
              this.GT.current.style.display = "none";
              this.GTB.current.style.transform = "scaleX(0)";
            }}
          >
            <Link
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              to="/introduce/ve-gems-edu"
              className={
                this.props.location.pathname === "/" ? "NBI" : "NBI c-w"
              }
            >
              <div
                className={
                  this.props.location.pathname === "/" ? "NBB" : "NBB NBB2"
                }
                ref={this.GTB}
              ></div>
              GIỚI THIỆU
            </Link>
            <div className="NBS NGT" ref={this.GT}>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/introduce/ve-gems-edu"
                className="NBST"
              >
                Về GEMS EDU
              </Link>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/introduce/tam-nhin-va-su-menh"
                className="NBST"
              >
                Tầm nhìn và sứ mệnh
              </Link>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/introduce/dao-tao-nhan-su"
                className="NBST NBSTE"
              >
                Đào tạo nhân sự
              </Link>
            </div>
          </div>
          <div
            className="NBIOut"
            onMouseEnter={() => {
              this.KH.current.style.display = "flex";
              this.KHB.current.style.transform = "scaleX(1)";
            }}
            onMouseLeave={() => {
              this.KH.current.style.display = "none";
              this.KHB.current.style.transform = "scaleX(0)";
            }}
          >
            <Link
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              to="/course/gems-stars"
              className={
                this.props.location.pathname === "/" ? "NBI" : "NBI c-w"
              }
            >
              <div
                className={
                  this.props.location.pathname === "/" ? "NBB" : "NBB NBB2"
                }
                ref={this.KHB}
              ></div>
              KHÓA HỌC
            </Link>
            <div className="NBS NKH" ref={this.KH}>
              <a href="https://igems.com.vn/" className="NBST">
                IGEMS Coaching online 1 on 1
              </a>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/course/gems-stars"
                className="NBST"
              >
                Gems Stars (3-5 tuổi)
              </Link>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/course/gems-fighters"
                className="NBST"
              >
                Gems Fighters (6-11 tuổi)
              </Link>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/course/gems-explorers"
                className="NBST"
              >
                Gems Explorer (12-15 tuổi)
              </Link>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/course/luyen-thi-ielts"
                className="NBST"
              >
                Luyện thi IELTS
              </Link>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/course/luyen-thi-cambridge"
                className="NBST"
              >
                Luyện thi Cambridge
              </Link>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/course/cau-lac-bo-va-khoa-hoc-ngoai-khoa"
                className="NBST"
              >
                Câu lạc bộ và Khóa học ngoại khóa
              </Link>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/course/lo-trinh-hoc-va-phuong-phap-dao-tao"
                className="NBST"
              >
                Lộ trình học tập và Phương pháp đào tạo
              </Link>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/course/trai-he-supercamp"
                className="NBST NBSTE"
              >
                Trại hè Supercamp
              </Link>
            </div>
          </div>

          <div
            className="NBIOut"
            onMouseEnter={() => {
              this.TT.current.style.display = "flex";
              this.TTB.current.style.transform = "scaleX(1)";
            }}
            onMouseLeave={() => {
              this.TT.current.style.display = "none";
              this.TTB.current.style.transform = "scaleX(0)";
            }}
          >
            <Link
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              to="/news/tin-tuc-va-su-kien"
              className={
                this.props.location.pathname === "/" ? "NBI" : "NBI c-w"
              }
            >
              <div
                className={
                  this.props.location.pathname === "/" ? "NBB" : "NBB NBB2"
                }
                ref={this.TTB}
              ></div>
              TIN TỨC SỰ KIỆN
            </Link>
            <div className="NBS NTT" ref={this.TT}>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/news/tin-tuc-va-su-kien"
                className="NBST"
              >
                Tin tức và Sự kiện
              </Link>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/news/doi-tac-kinh-doanh"
                className="NBST"
              >
                Đối tác kinh doanh
              </Link>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/news/hinh-anh-va-video"
                className="NBST"
              >
                Hình ảnh và Video
              </Link>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/news/lich-hoc-trai-nghiem"
                className="NBST"
              >
                Lịch học trải nghiệm
              </Link>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/news/tuyen-dung"
                className="NBST NBSTE"
              >
                Tuyển dụng
              </Link>
            </div>
          </div>

          <div
            className="NBIOut"
            onMouseEnter={() => {
              this.CN.current.style.display = "flex";
              this.CNB.current.style.transform = "scaleX(1)";
            }}
            onMouseLeave={() => {
              this.CN.current.style.display = "none";
              this.CNB.current.style.transform = "scaleX(0)";
            }}
          >
            <Link
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              to="/feel/cam-nhan-cua-hoc-vien"
              className={
                this.props.location.pathname === "/" ? "NBI" : "NBI c-w"
              }
            >
              <div
                className={
                  this.props.location.pathname === "/" ? "NBB" : "NBB NBB2"
                }
                ref={this.CNB}
              ></div>
              CẢM NHẬN
            </Link>
            <div className="NBS NCN" ref={this.CN}>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/feel/cam-nhan-cua-hoc-vien"
                className="NBST"
              >
                Cảm nhận của học viên
              </Link>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/feel/cam-nhan-cua-phu-huynh"
                className="NBST"
              >
                Cảm nhận của phụ huynh
              </Link>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/feel/hoc-vien-xuat-sac"
                className="NBST NBSTE"
              >
                Học viên xuất sắc
              </Link>
            </div>
          </div>

          <Link
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
            to="/contact"
            className={
              this.props.location.pathname === "/" ? "NBI p-r" : "NBI p-r c-w"
            }
            onMouseEnter={() => {
              this.LHB.current.style.transform = "scaleX(1)";
            }}
            onMouseLeave={() => {
              this.LHB.current.style.transform = "scaleX(0)";
            }}
          >
            <div
              className={
                this.props.location.pathname === "/" ? "NBB" : "NBB NBB2"
              }
              ref={this.LHB}
            ></div>
            LIÊN HỆ
          </Link>
        </div>
      </div>
    );
  }
}

export default withRouter(NavBar);
