import React from "react";
import { Axios } from "../ultis/axios";
import { src } from "../ultis/src";

export default class FeelParent extends React.Component {
  state = {};

  async componentDidMount() {
    let x = await Axios.get("/gedu/feel/cam-nhan-cua-phu-huynh");
    this.setState({
      ...x.data,
      now: x.data.list[0].video,
      nowtitle: x.data.list[0].title,
    });
    this.Video.current.innerHTML = x.data.list[0].video;
  }

  Video = React.createRef();

  render() {
    return (
      <div className="FormContent">
        <div className="FCT">
          <span className="FCTC">{this.state.title}</span>
        </div>
        <div className="PVNow">
          <div ref={this.Video} className="DivVideo"></div>
          <div className="PVNT">{this.state.nowtitle}</div>
        </div>
        {this.state.list ? (
          <div className="FeelParentList">
            {this.state.list.map((item) => (
              <div
                className="FPI"
                key={item.title}
                onClick={() => {
                  this.setState({ nowtitle: item.title });
                  this.Video.current.innerHTML = item.video;
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <img
                  src={`${src}/${item.img}`}
                  alt="x"
                  className="HFImg"
                />
                <div className="HFN">{item.title}</div>
                <div className="PlayVideo">
                  <i className="fas fa-play-circle"></i>
                </div>
              </div>
            ))}
          </div>
        ) : (
          "Loading..."
        )}
      </div>
    );
  }
}
