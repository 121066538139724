import React from "react";
import { Route, withRouter } from "react-router-dom";
import BestStudent from "./Feel/BestStudent";
import BSD from "./Feel/BSD";
import FeelParent from "./Feel/FeelParent";
import FeelStudent from "./Feel/FeelStudent";

class Feel extends React.Component {
  state = {
    category: "CẢM NHẬN",
    catelist: [
      { url: "/feel/cam-nhan-cua-hoc-vien", text: "Cảm nhận của học viên" },
      { url: "/feel/cam-nhan-cua-phu-huynh", text: "Cảm nhận của phụ huynh" },
      { url: "/feel/hoc-vien-xuat-sac", text: "Học viên xuất sắc" },
    ],
  };

  async componentDidMount() {
    this.props.setCF(this.state.category, this.state.catelist);
  }

  render() {
    return (
      <div className="BodySub bc-s Feel">
        <Route
          path="/feel/cam-nhan-cua-hoc-vien"
          render={() => <FeelStudent />}
        />
        <Route
          path="/feel/cam-nhan-cua-phu-huynh"
          render={() => <FeelParent />}
        />
        <Route
          path="/feel/hoc-vien-xuat-sac"
          exact
          render={() => <BestStudent />}
        />
        <Route path="/feel/hoc-vien-xuat-sac/:id" render={() => <BSD />} />
      </div>
    );
  }
}

export default withRouter(Feel);
