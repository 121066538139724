import React from "react";
import { Axios } from "../ultis/axios";
import { src } from "../ultis/src";

export default class FeelStudent extends React.Component {
  state = {};

  async componentDidMount() {
    let x = await Axios.get("/gedu/feel/cam-nhan-cua-hoc-vien");
    if (x.data) {
      this.setState({ ...x.data });
    }
  }

  render() {
    return (
      <div className="FormContent">
        <div className="FCT">
          <span className="FCTC">{this.state.title}</span>
        </div>
        {this.state.list ? (
          <div className="PostList mt-20">
            {this.state.list.map((item) => (
              <div
                key={item.id}
                className={
                  this.state.list.indexOf(item) % 2 === 0
                    ? "FeelItem"
                    : "FeelItem fd-r FeelItemR"
                }
              >
                <div
                  className="FeelImg"
                  style={{
                    backgroundImage: `url(${src}/${item.img})`,
                  }}
                ></div>
                <div className="FeelContent">
                  <div
                    className={
                      this.state.list.indexOf(item) % 2 === 0
                        ? "FeelName"
                        : "FeelName FeelNameR"
                    }
                  >
                    {item.title}
                  </div>
                  <div
                    className={
                      this.state.list.indexOf(item) % 2 === 0
                        ? "FeelText"
                        : "FeelText FeelTextR"
                    }
                  >
                    {item.content}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          "Loading..."
        )}
      </div>
    );
  }
}
