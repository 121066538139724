import React from "react";
import "./App.css";
import Footer from "./Footer";
import NavBar from "./NavBar";
import { Route, withRouter } from "react-router-dom";
import Home from "./Home";
import Intro from "./Intro";
import Course from "./Course";
import Feel from "./Feel";
import Contact from "./Contact";
import News from "./News";
import FormTitle from "./FormTitle";
import NavBarCross from "./NavBarCross";

class App extends React.Component {
  state = {
    catelist: [],
    NBC: "d-flex fd-c NavBarCross r-300",
    SBar: "0",
  };

  handleScroll = async () => {
    let x = window.scrollY;
    let y = document.body.offsetHeight - window.innerHeight + 120;
    await this.setState({ SBar: (x / y) * 100 + "%" });
  };

  componentDidMount() {
    let url = this.props.location.pathname;
    if (url === "/news" || url === "/news/") {
      this.props.history.push("/news/tin-tuc-va-su-kien");
    }
    window.addEventListener("scroll", this.handleScroll);
  }

  setCate = (x, y) => {
    this.setState({ cate: x, catelist: y });
  };

  onNBC = (e) => {
    e.stopPropagation();
    this.setState({ NBC: "d-flex fd-c NavBarCross" });
  };
  offNBC = () => {
    this.setState({ NBC: "d-flex fd-c NavBarCross r-300" });
  };

  render() {
    return (
      <div className="App">
        <NavBar onNBC={this.onNBC} offNBC={this.offNBC} />
        <div className="SBar" style={{ width: this.state.SBar }}></div>
        <NavBarCross className={this.state.NBC} offNBC={this.offNBC} />
        <div className="BG"></div>
        {this.props.location.pathname !== "/" &&
        this.props.location.pathname !== "/contact" ? (
          <FormTitle
            offNBC={this.offNBC}
            category={this.state.cate}
            catelist={this.state.catelist}
          />
        ) : null}

        <div className="BodyPage" onClick={this.offNBC}>
          <Route path="/" exact render={() => <Home />}></Route>
          <Route
            path="/introduce/:id"
            render={() => <Intro setCF={this.setCate} />}
          ></Route>
          <Route
            path="/course"
            render={() => <Course setCF={this.setCate} />}
          ></Route>
          <Route
            path="/news"
            render={() => <News setCF={this.setCate} />}
          ></Route>
          <Route
            path="/feel"
            render={() => <Feel setCF={this.setCate} />}
          ></Route>
          <Route path="/contact" render={() => <Contact />}></Route>
          <Footer></Footer>
        </div>
        <div
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
          className="GoTop"
          title="Go to Top"
        >
          <i className="fas fa-arrow-up"></i>
          {/* <span>{this.App.current.style.display}</span> */}
        </div>
      </div>
    );
  }
}

export default withRouter(App);
