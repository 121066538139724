import React from "react";
import { Link, withRouter } from "react-router-dom";

class NavBarCross extends React.Component {
  state = { on: "" };

  render() {
    return (
      <div className={this.props.className}>
        <Link
          to="/"
          className="NBCI"
          onClick={() => {
            this.props.offNBC();
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          <i className="fas fa-home NBCIcon"></i>TRANG CHỦ
        </Link>
        <div
          className="NBCI"
          onClick={() => {
            if (this.state.on !== "GT") {
              return this.setState({ on: "GT" });
            }
            return this.setState({ on: "" });
          }}
        >
          <i className="fas fa-info-circle NBCIcon"></i>GIỚI THIỆU
          <i className="fas fa-sort-down pos-r"></i>
        </div>
        <div
          className="NBCS"
          style={{ height: this.state.on !== "GT" ? "0" : "135px" }}
        >
          <Link
            to="/introduce/ve-gems-edu"
            className="NBCI NBCISub"
            onClick={() => {
              this.props.offNBC();
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            Về GEMS EDU
          </Link>
          <Link
            to="/introduce/tam-nhin-va-su-menh"
            className="NBCI NBCISub"
            onClick={() => {
              this.props.offNBC();
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            Tầm nhìn và sứ mệnh
          </Link>
          <Link
            to="/introduce/dao-tao-nhan-su"
            className="NBCI NBCISub"
            onClick={() => {
              this.props.offNBC();
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            Đào tạo nhân sự
          </Link>
        </div>

        <div
          className="NBCI"
          onClick={() => {
            if (this.state.on !== "KH") {
              return this.setState({ on: "KH" });
            }
            return this.setState({ on: "" });
          }}
        >
          <i className="fas fa-graduation-cap NBCIcon"></i>KHÓA HỌC
          <i className="fas fa-sort-down pos-r"></i>
        </div>
        <div
          className="NBCS"
          style={{ height: this.state.on !== "KH" ? "0" : "447px" }}
        >
          <a href="https://igems.com.vn/" className="NBCI NBCISub">
            IGEMS Coaching online 1 on 1
          </a>
          <Link
            to="/course/gems-stars"
            className="NBCI NBCISub"
            onClick={() => {
              this.props.offNBC();
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            Gems Stars (3-5 tuổi)
          </Link>
          <Link
            to="/course/gems-fighters"
            className="NBCI NBCISub"
            onClick={() => {
              this.props.offNBC();
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            Gems Fighters (6-11 tuổi)
          </Link>
          <Link
            to="/course/gems-explorers"
            className="NBCI NBCISub"
            onClick={() => {
              this.props.offNBC();
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            Gems Explorer (12-15 tuổi)
          </Link>
          <Link
            to="/course/luyen-thi-ielts"
            className="NBCI NBCISub"
            onClick={() => {
              this.props.offNBC();
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            Luyện thi IELTS
          </Link>
          <Link
            to="/course/luyen-thi-cambridge"
            className="NBCI NBCISub"
            onClick={() => {
              this.props.offNBC();
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            Luyện thi Cambridge
          </Link>
          <Link
            to="/course/cau-lac-bo-va-khoa-hoc-ngoai-khoa"
            className="NBCI NBCISub"
            onClick={() => {
              this.props.offNBC();
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            Câu lạc bộ và Khóa học ngoại khóa
          </Link>
          <Link
            to="/course/lo-trinh-hoc-va-phuong-phap-dao-tao"
            className="NBCI NBCISub"
            onClick={() => {
              this.props.offNBC();
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            Lộ trình học tập và Phương pháp đào tạo
          </Link>
          <Link
            to="/course/trai-he-supercamp"
            className="NBCI NBCISub"
            onClick={() => {
              this.props.offNBC();
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            Trại hè Supercamp
          </Link>
        </div>

        <div
          className="NBCI"
          onClick={() => {
            if (this.state.on !== "TT") {
              return this.setState({ on: "TT" });
            }
            return this.setState({ on: "" });
          }}
        >
          <i className="fas fa-newspaper NBCIcon"></i>TIN TỨC SỰ KIỆN
          <i className="fas fa-sort-down pos-r"></i>
        </div>
        <div
          className="NBCS"
          style={{ height: this.state.on !== "TT" ? "0" : "225px" }}
        >
          <Link
            to="/news/tin-tuc-va-su-kien"
            className="NBCI NBCISub"
            onClick={() => {
              this.props.offNBC();
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            Tin tức và Sự kiện
          </Link>
          <Link
            to="/news/doi-tac-kinh-doanh"
            className="NBCI NBCISub"
            onClick={() => {
              this.props.offNBC();
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            Đối tác kinh doanh
          </Link>
          <Link
            to="/news/hinh-anh-va-video"
            className="NBCI NBCISub"
            onClick={() => {
              this.props.offNBC();
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            Hình ảnh và Video
          </Link>
          <Link
            to="/news/lich-hoc-trai-nghiem"
            className="NBCI NBCISub"
            onClick={() => {
              this.props.offNBC();
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            Lịch học trải nghiệm
          </Link>
          <Link
            to="/news/tuyen-dung"
            className="NBCI NBCISub"
            onClick={() => {
              this.props.offNBC();
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            Tuyển dụng
          </Link>
        </div>

        <div
          className="NBCI"
          onClick={() => {
            if (this.state.on !== "CN") {
              return this.setState({ on: "CN" });
            }
            return this.setState({ on: "" });
          }}
        >
          <i className="fas fa-comment NBCIcon"></i>CẢM NHẬN
          <i className="fas fa-sort-down pos-r"></i>
        </div>
        <div
          className="NBCS"
          style={{ height: this.state.on !== "CN" ? "0" : "135px" }}
        >
          <Link
            to="/feel/cam-nhan-cua-hoc-vien"
            className="NBCI NBCISub"
            onClick={() => {
              this.props.offNBC();
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            Cảm nhận của học viên
          </Link>
          <Link
            to="/feel/cam-nhan-cua-phu-huynh"
            className="NBCI NBCISub"
            onClick={() => {
              this.props.offNBC();
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            Cảm nhận của phụ huynh
          </Link>
          <Link
            to="/feel/hoc-vien-xuat-sac"
            className="NBCI NBCISub"
            onClick={() => {
              this.props.offNBC();
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            Học viên xuất sắc
          </Link>
        </div>
        <Link
          to="/contact"
          className="NBCI"
          onClick={() => {
            this.props.offNBC();
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          <i className="fas fa-address-card NBCIcon"></i>LIÊN HỆ
        </Link>
      </div>
    );
  }
}

export default withRouter(NavBarCross);
