import React from "react";
import Banner from "./Banner";
import HomeCourse from "./HomeCourse";
import HomeFeel from "./HomeFeel";
import HomePromise from "./HomePromise";
import HomeRegister from "./HomeRegister";
import HomeStudent from "./HomeStudent";

export default class Home extends React.Component {
  render() {
    return (
      <div className="Home">
        <Banner />
        <HomeCourse />
        <HomeRegister />
        <HomeStudent />
        <HomePromise />
        <HomeFeel />
      </div>
    );
  }
}
