import React from "react";
import { Axios } from "../ultis/axios";
import { src } from "../ultis/src";

export default class DTKD extends React.Component {
  state = {};

  async componentDidMount() {
    let x = await Axios.get("/gedu/news/doi-tac-kinh-doanh");
    if (x.data) {
      this.setState({ ...x.data });
    }
  }
  render() {
    return (
      <div className="FormContent">
        {this.state.title ? (
          <div className="DTKD">
            {this.state.list.map((item) => (
              <a href={item.link} key={item.link}>
                <img
                  className="DTKDImg"
                  alt={item.link}
                  src={`${src}/${item.img}`}
                />
              </a>
            ))}
          </div>
        ) : (
          "Data not found"
        )}
        <div className="FCT">
          <span className="FCTC">{this.state.title}</span>
        </div>
      </div>
    );
  }
}
