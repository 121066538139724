import React from "react";
import B1 from "./img/B1.jpg";
import B2 from "./img/B2.jpg";
import B3 from "./img/B3.jpg";
import B4 from "./img/B4.jpg";

export default class Banner extends React.Component {
  state = {
    now: 1,
  };

  B1 = React.createRef();
  B2 = React.createRef();
  B3 = React.createRef();
  B4 = React.createRef();

  setBanner = async (x) => {
    if (x === 1) {
      if (this.state.now === x) {
        return;
      }
      this.B1.current.style.transition = "1s";
      this.B1.current.style.left = "0";
      if (this.state.now === 2) {
        this.B2.current.style.transition = "1s";
        this.B2.current.style.left = "-100%";
        this.B3.current.style.transition = "none";
        this.B4.current.style.transition = "none";
        this.B3.current.style.left = "100%";
        this.B4.current.style.left = "100%";
      }
      if (this.state.now === 3) {
        this.B3.current.style.transition = "1s";
        this.B3.current.style.left = "-100%";
        this.B2.current.style.transition = "none";
        this.B4.current.style.transition = "none";
        this.B2.current.style.left = "100%";
        this.B4.current.style.left = "100%";
      }
      if (this.state.now === 4) {
        this.B4.current.style.transition = "1s";
        this.B4.current.style.left = "-100%";
        this.B2.current.style.transition = "none";
        this.B3.current.style.transition = "none";
        this.B3.current.style.left = "100%";
        this.B2.current.style.left = "100%";
      }
    }
    if (x === 2) {
      if (this.state.now === x) {
        return;
      }
      this.B2.current.style.transition = "1s";
      this.B2.current.style.left = "0";
      if (this.state.now === 1) {
        this.B1.current.style.transition = "1s";
        this.B1.current.style.left = "-100%";
        this.B3.current.style.transition = "none";
        this.B4.current.style.transition = "none";
        this.B3.current.style.left = "100%";
        this.B4.current.style.left = "100%";
      }
      if (this.state.now === 3) {
        this.B3.current.style.transition = "1s";
        this.B3.current.style.left = "-100%";
        this.B1.current.style.transition = "none";
        this.B4.current.style.transition = "none";
        this.B1.current.style.left = "100%";
        this.B4.current.style.left = "100%";
      }
      if (this.state.now === 4) {
        this.B4.current.style.transition = "1s";
        this.B4.current.style.left = "-100%";
        this.B3.current.style.transition = "none";
        this.B1.current.style.transition = "none";
        this.B3.current.style.left = "100%";
        this.B1.current.style.left = "100%";
      }
    }
    if (x === 3) {
      if (this.state.now === x) {
        return;
      }
      this.B3.current.style.transition = "1s";
      this.B3.current.style.left = "0";
      if (this.state.now === 2) {
        this.B2.current.style.transition = "1s";
        this.B2.current.style.left = "-100%";
        this.B1.current.style.transition = "none";
        this.B4.current.style.transition = "none";
        this.B1.current.style.left = "100%";
        this.B4.current.style.left = "100%";
      }
      if (this.state.now === 1) {
        this.B1.current.style.transition = "1s";
        this.B1.current.style.left = "-100%";
        this.B2.current.style.transition = "none";
        this.B4.current.style.transition = "none";
        this.B4.current.style.left = "100%";
      }
      if (this.state.now === 4) {
        this.B4.current.style.transition = "1s";
        this.B4.current.style.left = "-100%";
        this.B2.current.style.transition = "none";
        this.B1.current.style.transition = "none";
        this.B2.current.style.left = "100%";
        this.B1.current.style.left = "100%";
      }
    }
    if (x === 4) {
      if (this.state.now === x) {
        return;
      }
      this.B4.current.style.transition = "1s";
      this.B4.current.style.left = "0";
      if (this.state.now === 2) {
        this.B2.current.style.transition = "1s";
        this.B2.current.style.left = "-100%";
        this.B3.current.style.transition = "none";
        this.B1.current.style.transition = "none";
        this.B3.current.style.left = "100%";
        this.B1.current.style.left = "100%";
      }
      if (this.state.now === 3) {
        this.B3.current.style.transition = "1s";
        this.B3.current.style.left = "-100%";
        this.B2.current.style.transition = "none";
        this.B1.current.style.transition = "none";
        this.B2.current.style.left = "100%";
        this.B1.current.style.left = "100%";
      }
      if (this.state.now === 1) {
        this.B1.current.style.transition = "1s";
        this.B1.current.style.left = "-100%";
        this.B3.current.style.transition = "none";
        this.B2.current.style.transition = "none";
        this.B3.current.style.left = "100%";
        this.B2.current.style.left = "100%";
      }
    }
  };

  setSlide = () =>
    setTimeout(async () => {
      let x = this.state.now + 1;
      if (x === 5) {
        x = 1;
      }

      await this.setBanner(x);
      await this.setState({ now: x });

      this.setSlide();
    }, 5000);

  async componentDidMount() {
    this.B1.current.style.display = "block";
    this.B2.current.style.display = "block";
    this.B3.current.style.display = "block";
    this.B4.current.style.display = "block";
    this.B2.current.style.left = "100%";
    this.B3.current.style.left = "100%";
    this.B4.current.style.left = "100%";
    this.B1.current.style.left = "0";
    this.setIn = setInterval(async () => {
      let x = this.state.now + 1;
      if (x === 5) {
        x = 1;
      }

      await this.setBanner(x);
      await this.setState({ now: x });
    }, 5000);
    // this.setSlide = setTimeout(async () => {
    //   let x = this.state.now + 1;
    //   if (x === 5) {
    //     x = 1;
    //   }

    //   await this.setBanner(x);
    //   await this.setState({ now: x });

    //   this.setSlide();
    // }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.setIn);
  }

  render() {
    return (
      <div className="Banner">
        <div className="ButtonBanner">
          <div
            className={this.state.now === 1 ? "BB bc-bb" : "BB"}
            onClick={async () => {
              await this.setBanner(1);
              await this.setState({ now: 1 });
            }}
          ></div>
          <div
            className={this.state.now === 2 ? "BB bc-bb" : "BB"}
            onClick={async () => {
              await this.setBanner(2);
              await this.setState({ now: 2 });
            }}
          ></div>
          <div
            className={this.state.now === 3 ? "BB bc-bb" : "BB"}
            onClick={async () => {
              await this.setBanner(3);
              await this.setState({ now: 3 });
            }}
          ></div>
          <div
            className={this.state.now === 4 ? "BB bc-bb" : "BB"}
            onClick={async () => {
              await this.setBanner(4);
              await this.setState({ now: 4 });
            }}
          ></div>
        </div>
        <img className="BI" src={B1} alt="Banner" ref={this.B1}></img>
        <img className="BI" src={B2} alt="Banner" ref={this.B2}></img>
        <img className="BI" src={B3} alt="Banner" ref={this.B3}></img>
        <img className="BI" src={B4} alt="Banner" ref={this.B4}></img>
      </div>
    );
  }
}
