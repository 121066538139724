import React from "react";
import { Axios } from "./ultis/axios";

export default class HomeRegister extends React.Component {
  state = { name: "", age: "", email: "", phone: "" };

  Name = React.createRef();
  Age = React.createRef();
  Email = React.createRef();
  Phone = React.createRef();
  NameErr = React.createRef();
  AgeErr = React.createRef();
  EmailErr = React.createRef();
  PhoneErr = React.createRef();

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.Name.current.style.border = "2px solid #0ab1e7";
    this.NameErr.current.innerText = "";
    this.Age.current.style.border = "2px solid #0ab1e7";
    this.AgeErr.current.innerText = "";
    this.Email.current.style.border = "2px solid #0ab1e7";
    this.EmailErr.current.innerText = "";
    this.Phone.current.style.border = "2px solid #0ab1e7";
    this.PhoneErr.current.innerText = "";
  };

  validateEmail = (email) => {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  onSubmit = async () => {
    if (this.state.name === "") {
      this.Name.current.style.border = "2px solid red";
      this.NameErr.current.innerText = "Please enter name";
    }
    if (this.state.age === "") {
      this.Age.current.style.border = "2px solid red";
      this.AgeErr.current.innerText = "Please enter age";
    }
    if (this.state.email === "") {
      this.Email.current.style.border = "2px solid red";
      this.EmailErr.current.innerText = "Please enter email";
    } else if (!this.validateEmail(this.state.email)) {
      console.log(this.validateEmail(this.state.email));
      this.Email.current.style.border = "2px solid red";
      this.EmailErr.current.innerText = "Email false";
    }
    if (this.state.phone === "") {
      this.Phone.current.style.border = "2px solid red";
      this.PhoneErr.current.innerText = "Please enter phone number";
    } else if (this.state.phone.length < 10 || this.state.phone.length > 15) {
      this.Phone.current.style.border = "2px solid red";
      this.PhoneErr.current.innerText = "Phone false";
    }
    if (
      this.NameErr.current.innerText === "" &&
      this.AgeErr.current.innerText === "" &&
      this.EmailErr.current.innerText === "" &&
      this.PhoneErr.current.innerText === ""
    ) {
      Axios.post("/gedu/register", {
        name: this.state.name,
        age: this.state.age,
        phone: this.state.phone,
        email: this.state.email,
      });
      alert(
        "Cảm ơn đã đăng kí, GEMS EDU sẽ liên hệ tư vấn trong thời gian sớm nhất"
      );
    }
  };

  render() {
    return (
      <div className="HomeRegister">
        <div className="Why">
          <div className="WT">
            Vì sao <span className="fw-bd fs-50">GEMS EDU</span>
            <br />
            là lựa chọn <span className="c-o">tốt nhất</span>
            <br />
            dành cho bạn ?
          </div>
          <div className="WL">
            <div className="WLB WL1"></div>
            <div className="WLI">
              <i className="far fa-check-circle c-o"></i>
              <div className="WLIT">
                <b>Chương trình học Quốc Tế chuẩn Cambridge</b>
                <br />
                <span className="fs-16">- Giáo trình chuẩn Quốc Tế.</span>
                <br />
                <span className="fs-16">
                  - 100% giáo viên Bản ngữ được đào tạo theo tiêu chuẩn Quốc Tế.
                </span>
                <br />
                <span className="fs-16">
                  - Lộ trình học tập cá nhân hóa, Tối ưu hóa hiệu quả gấp 5 lần.
                </span>
                <br />
                <span className="fs-16">- Cam kết đầu ra theo thời gian.</span>
              </div>
            </div>
            <div className="WLB WL2"></div>
            <div className="WLI">
              <i className="far fa-check-circle c-o"></i>
              <div className="WLIT">
                <b>Phương pháp học tập ESL hiện đại</b>
                <br />
                <span className="fs-16">
                  - Tạo bước đệm vững chắc về kiến thức, kỹ năng cho học viên.
                </span>
                <br />
                <span className="fs-16">
                  - Tham gia các hoạt động trải nghiệm trong và ngoài nước.
                </span>
              </div>
            </div>
            <div className="WLB WL5"></div>
            <div className="WLI">
              <i className="far fa-check-circle c-o"></i>
              <div className="WLIT">
                <b>Lớp học tiêu chuẩn quốc tế</b>
                <br />
                <span className="fs-16">- 12 đến 14 thành viên/lớp.</span>
                <br />
                <span className="fs-16">
                  - Cơ sở vật chất hiện đại, tiêu chuẩn quốc tế.
                </span>
              </div>
            </div>
            <div className="WLB WL5"></div>
            <div className="WLI">
              <i className="far fa-check-circle c-o"></i>
              <div className="WLIT">
                <b>Đào tạo hơn 1.000 học viên mỗi năm.</b>
                <br />
              </div>
            </div>
            <div className="WLB WL3"></div>
            <div className="WLI">
              <i className="far fa-check-circle c-o"></i>
              <div className="WLIT">
                <b>Hệ thống hố trợ 24/7.</b>
                <br />
              </div>
            </div>
            <div className="WLB WL1"></div>
          </div>
        </div>
        <div className="FormRegister">
          <div className="FRT">
            <div className="FRT1">ĐĂNG KÝ NGAY</div>
            <div className="FRT2">
              để được tư vấn về các chương trình, khóa học, tham gia học miễn
              phí
            </div>
          </div>
          <input
            ref={this.Name}
            name="name"
            onChange={this.onChange}
            className="FRI"
            type="text"
            placeholder="Tên"
          ></input>
          <div ref={this.NameErr} className="c-r"></div>
          <input
            ref={this.Age}
            name="age"
            onChange={this.onChange}
            className="FRI"
            type="number"
            placeholder="Tuổi"
          ></input>
          <div ref={this.AgeErr} className="c-r"></div>
          <input
            ref={this.Email}
            name="email"
            onChange={this.onChange}
            className="FRI"
            type="text"
            placeholder="Email phụ huynh"
          ></input>
          <div ref={this.EmailErr} className="c-r"></div>
          <input
            ref={this.Phone}
            name="phone"
            onChange={this.onChange}
            className="FRI"
            type="number"
            placeholder="Số điện thoại phụ huynh"
          ></input>
          <div ref={this.PhoneErr} className="c-r"></div>
          <div className="FRB" onClick={this.onSubmit}>
            ĐĂNG KÝ
          </div>
        </div>
      </div>
    );
  }
}
