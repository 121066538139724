import React from "react";
import { Link } from "react-router-dom";
import { Axios } from "../ultis/axios";
import { src } from "../ultis/src";

export default class BestStudent extends React.Component {
  state = {};

  async componentDidMount() {
    let x = await Axios.get("/gedu/feel/hoc-vien-xuat-sac");
    if (x.data) {
      this.setState({ ...x.data });
    }
  }

  render() {
    return (
      <div className="FormContent">
        <div className="FCT">
          <span className="FCTC">{this.state.title}</span>
        </div>
        {this.state.list ? (
          <div className="PostList">
            {this.state.list.map((item) => (
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to={`/feel/hoc-vien-xuat-sac/${item.id}`}
                key={item.id}
                className="PLItem"
              >
                <div
                  className="PLImg"
                  style={{
                    backgroundImage: `url(${src}/${item.img})`,
                  }}
                ></div>
                <div className="PLContent">
                  <div className="PLTitle fw-bd">{item.title}</div>
                </div>
              </Link>
            ))}
          </div>
        ) : (
          "Loading..."
        )}
      </div>
    );
  }
}
