import React from "react";
import { withRouter } from "react-router-dom";
import { Axios } from "../ultis/axios";
import { src } from "../ultis/src";

class BSD extends React.Component {
  state = {};

  async componentDidMount() {
    let x = await Axios.get(
      `/gedu/feel/hoc-vien-xuat-sac/${
        this.props.location.pathname.split("/")[3]
      }`
    );
    if (x.data) {
      this.setState({ ...x.data });
    }
  }

  render() {
    return (
      <div className="FormContent">
        {this.state.title ? (
          <div className="Post">
            <img
              className="PostImg BSDI"
              src={`${src}/${this.state.img}`}
              alt={this.state.img}
            ></img>
            <div
              className="PostContent"
              dangerouslySetInnerHTML={{ __html: this.state.content }}
            ></div>
          </div>
        ) : (
          "Data not found"
        )}
        <div className="FCT">
          <span className="FCTC">{this.state.title}</span>
        </div>
      </div>
    );
  }
}

export default withRouter(BSD);
