import React from "react";
import { Link } from "react-router-dom";
import Course1 from "./img/Course1.png";
import Course2 from "./img/B4.jpg";
import Course3 from "./img/Course3.jpg";

export default class HomeCourse extends React.Component {
  state = { now: 1, end: false };

  nextItem = async () => {
    let x = this.state.now + 1;
    if (x === 4) {
      return;
    }
    await this.setState({ now: x });
    if (this.state.now === 3) {
      this.setState({ end: true });
    } else {
      this.setState({ end: false });
    }
  };

  prevItem = async () => {
    let x = this.state.now - 1;
    if (x === 0) {
      return;
    }
    await this.setState({ now: x });
    if (this.state.now === 3) {
      this.setState({ end: true });
    } else {
      this.setState({ end: false });
    }
    console.log(this.state.now, this.state.end);
  };

  prev = React.createRef();
  next = React.createRef();

  render() {
    return (
      <div className="HomeCourse">
        <div className="HCT">HỆ THỐNG ĐÀO TẠO</div>
        <div className="HCL">
          <i
            className={
              this.state.now === 1
                ? "fas fa-chevron-left HCIprev c-gray HCIB"
                : "fas fa-chevron-left HCIprev HCIB"
            }
            onClick={this.prevItem}
            ref={this.prev}
          ></i>
          <i
            className={
              this.state.end === true
                ? "fas fa-chevron-right HCInext c-gray HCIB"
                : "fas fa-chevron-right HCInext HCIB"
            }
            onClick={this.nextItem}
            ref={this.next}
          ></i>
          <div className="HCILC">
            <div
              className={
                this.state.now === 1
                  ? "HCIL HCIL1"
                  : this.state.now === 2
                  ? "HCIL HCIL2"
                  : "HCIL HCIL3"
              }
            >
              <Link
                to="/course/gems-stars"
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <div className="HCI HCI1">
                  <div className="HCIimgC">
                    <div
                      className="HCIimage"
                      style={{ backgroundImage: `url(${Course1})` }}
                    ></div>
                  </div>
                  <div className="HCItitle">Hệ thống Tiếng Anh trẻ em</div>
                </div>
              </Link>
              <Link
                to="/course/trai-he-supercamp"
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <div className="HCI HCI2">
                  <div className="HCIimgC">
                    <div
                      className="HCIimage"
                      style={{ backgroundImage: `url(${Course2})` }}
                    ></div>
                  </div>

                  <div className="HCItitle">
                    Trại hè SuperCamp bản quyền từ Mỹ
                  </div>
                </div>
              </Link>
              <a href="https://igems.com.vn/">
                <div className="HCI HCI3">
                  <div className="HCIimgC">
                    <div
                      className="HCIimage"
                      style={{ backgroundImage: `url(${Course3})` }}
                    ></div>
                  </div>
                  <div className="HCItitle">IGEMS Online English Coaching 1 - 1</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
